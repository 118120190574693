<template>
 <div :class="windowClass">
   <v-container>
    <v-row>
      <v-col cols="12">
                <v-card>
               <v-toolbar>
                 <v-icon left color="info">mdi-cash</v-icon> Cash-Outs
                 <v-spacer/>
                 
                
               </v-toolbar>
               <v-card-text>
                <v-data-table
                    class="va-table"
                    dense
                    :headers="headers"
                    :items="cashouts"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td>{{ item.user_id}}</td>
                            <td>{{ $money(item.amount) }}</td>
                            <td>{{ $money(item.fee) }} </td>
                            <td>{{ $money(item.amount-item.fee) }}</td>
                            <td>{{ $moment(item.req_dt).format("YYYY-MM-DD HH:mm a") }}</td>
                            <td>
                                <div>
                              <v-chip
                                    class="mr-2"
                                    :color="statusColor(item.status)"
                                    label
                                    small
                                    text-color="white"
                                    >
                                   {{item.status}}
                                    </v-chip>
                                  </div>
                                  {{item.remarks}}
                            </td>
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
               </v-card-text>
             </v-card>
          </v-col>
    </v-row>
    </v-container>
    <va-cashout-confirm :show="form" :cashout="select_item" @DialogEvent="afEvent" />
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        areaform: false,
        state: 'new',
        select_item: {},
        cashouts:[],
        headers: [
            { text: 'Acct no.',},
            { text: 'Req Amount',},
            { text: 'Fee',},
            { text: 'Recievable',},
            { text: 'Req. DT',},
            { text: 'Status',}
        ],
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
         this.getCashout()
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      afEvent() {
        this.form = false
        this.getCashout()
      },
      getCashout() {
        this.cashouts = []
      this.$http.post("get_cashout", {type:"Partner",status: "All"}).then(response => {
           console.log(response.data)
           this.cashouts = response.data.items
          }).catch(e => {
            console.log(e.data)
          })
    },
    statusColor(status){
        if(status == "Pending") {
          return "warning"
        } else if(status == "Completed") {
          return "success"
        } else {
          return "#ddd"
        }
    }
     
    }
  }
</script>